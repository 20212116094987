import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import MetaTags from "react-meta-tags"
import { nanoid } from "nanoid"
import { v4 as uuidv4 } from "uuid"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap"
import {
  addNewCampaign as onAddNewCampaign,
  getCampaignDetail as onGetCampaignDetail,
  getCampaigns as onGetCampaigns,
} from "store/actions"
import { countries } from "../../../data/countries"

const NewCampaign = props => {
  const dispatch = useDispatch()
  const { history } = props
  const { campaignId } = useParams()

  const { campaignDetail } = useSelector(state => ({
    campaignDetail: state.campaigns.campaignDetail,
  }))

  const [subject, setSubject] = useState("")
  const [delivered, setDelivered] = useState(0)
  const [deliveredPercentage, setDeliveredPercentage] = useState(0)
  const [opened, setOpened] = useState(0)
  const [openedPercentage, setOpenedPercentage] = useState(0)
  const [unOpened, setUnOpened] = useState(0)
  const [unOpenedPercentage, setUnOpenedPercentage] = useState(0)
  const [clicked, setClicked] = useState(0)
  const [clickedPercentage, setClickedPercentage] = useState(0)
  const [dateTime, setDateTime] = useState("")
  const [campaignOrder, setCampaignOrder] = useState(0)
  const [active, setActive] = useState(false)
  const [countriesList, setCountriesList] = useState([])
  const [openedPercentageCountry, setOpenedPercentageCountry] = useState(0)
  const [clickedPercentageCountry, setClickedPercentageCountry] = useState(0)
  const [openedBalanceCountry, setOpenedBalanceCountry] = useState(0)
  const [clickedBalanceCountry, setClickedBalanceCountry] = useState(0)

  useEffect(() => {
    if (campaignId) {
      dispatch(onGetCampaignDetail(campaignId))
    }
  }, [campaignId, onGetCampaignDetail])

  useEffect(() => {
    if (campaignDetail) {
      const {
        active,
        campaignOrder,
        clickedCount,
        countries,
        deliveredCount,
        openedCount,
        sendTimeStart,
      } = campaignDetail
      setActive(active || false)
      setCampaignOrder(campaignOrder || 0)
      setClicked(clickedCount || 0)
      setCountriesList(countries || [])
      setDelivered(deliveredCount || 0)
      setOpened(openedCount || 0)
      setDateTime(sendTimeStart || "")
      setSubject(campaignDetail.subject || "")
    }
  }, [campaignDetail])

  useEffect(() => {
    if (delivered > 0) {
      setDeliveredPercentage(((delivered / delivered) * 100).toFixed(2))
    } else {
      setDeliveredPercentage(0)
    }
  }, [delivered])

  useEffect(() => {
    if (delivered > 0 && opened > 0) {
      setOpenedPercentage(((opened / delivered) * 100).toFixed(2))
      setUnOpened(delivered - opened)
    } else {
      setOpenedPercentage(0)
      setUnOpened(delivered - opened)
    }
  }, [delivered, opened])

  useEffect(() => {
    if (unOpened > 0) {
      setUnOpenedPercentage(((unOpened / delivered) * 100).toFixed(2))
    } else {
      setUnOpenedPercentage(0)
    }
  }, [unOpened])

  useEffect(() => {
    if (clicked > 0 && opened > 0) {
      setClickedPercentage(((clicked / opened) * 100).toFixed(2))
    } else {
      setClickedPercentage(0)
    }
  }, [opened, clicked])

  useEffect(() => {
    if (countriesList.length > 0) {
      let openedPercentage = 0
      let clickedPercentage = 0
      let openedTotal = 0
      let clickedTotal = 0
      countriesList.forEach(country => {
        openedPercentage = openedPercentage + Number(country.openedPercentage)
        clickedPercentage =
          clickedPercentage + Number(country.clickedPercentage)
        openedTotal = openedTotal + Number(country.opened)
        clickedTotal = clickedTotal + Number(country.clicked)
      })
      setOpenedPercentageCountry(openedPercentage.toFixed(2))
      setClickedPercentageCountry(clickedPercentage.toFixed(2))
      setOpenedBalanceCountry(opened - openedTotal)
      setClickedBalanceCountry(clicked - clickedTotal)
    }
  }, [countriesList])

  const handleRowChange = (rowId, selector, event) => {
    const updatedCountriesList = countriesList.map(country => {
      if (country.id === rowId) {
        switch (selector) {
          case "country":
            const selectedCountry = event.target.value
            const selectedCountryArr = selectedCountry.split("|")
            if (selectedCountryArr.length > 1) {
              return {
                ...country,
                code: selectedCountryArr[0],
                name: selectedCountryArr[1],
              }
            }
            return country
            break
          case "opened":
            {
              if (opened > 0) {
                const openedPercentage = (
                  (event.target.value / opened) *
                  100
                ).toFixed(2)
                return {
                  ...country,
                  opened: Number(event.target.value),
                  openedPercentage: Number(openedPercentage),
                }
              }
              return country
            }
            break
          case "clicked":
            {
              if (clicked > 0) {
                const clickedPercentage = (
                  (event.target.value / clicked) *
                  100
                ).toFixed(2)
                return {
                  ...country,
                  clicked: Number(event.target.value),
                  clickedPercentage: Number(clickedPercentage),
                }
              }
              return country
            }
            break
          default:
            break
        }
      }
      return country
    })
    setCountriesList(updatedCountriesList)
  }

  const removeCountry = rowId => {
    const filteredCountriesList = countriesList.filter(
      country => country.id != rowId
    )
    setCountriesList(filteredCountriesList)
  }

  const save = () => {
    let campaign = {
      active,
      campaignOrder: Number(campaignOrder),
      clickedCount: Number(clicked),
      clickedPercentage: Number(clickedPercentage),
      countries: countriesList,
      deliveredCount: Number(delivered),
      id: campaignId,
      openedCount: Number(opened),
      openedPercentage: Number(openedPercentage),
      processedCount: Number(delivered),
      processedPercentage: Number(deliveredPercentage),
      sendTimeStart: dateTime,
      status: active ? "Sent" : "Draft",
      subject,
    }
    dispatch(onAddNewCampaign(campaign))
    dispatch(onGetCampaigns())
    history.push(`/xuawv2hyin`)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Campaign Detail | Incrementl - Mail Stats</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumbs title="Campaign" breadcrumbItem="Update Campaign" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Basic Information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <Form>
                    <Row>
                      <Col sm="12">
                        <div className="mb-3">
                          <Label htmlFor="subject">Subject</Label>
                          <Input
                            id="subject"
                            name="subject"
                            type="text"
                            className="form-control"
                            onChange={event => {
                              setSubject(event.target.value)
                            }}
                            value={subject}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <div className="mb-3">
                          <Label htmlFor="delivered">
                            Delivered{" "}
                            <span
                              className={
                                deliveredPercentage > 100 ||
                                deliveredPercentage <= 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({deliveredPercentage}%)
                            </span>
                          </Label>
                          <input
                            id="delivered"
                            name="delivered"
                            className="form-control"
                            type="number"
                            onChange={event => {
                              setDelivered(event.target.value)
                            }}
                            value={delivered}
                          />
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="mb-3">
                          <Label htmlFor="opened">
                            Opened{" "}
                            <span
                              className={
                                openedPercentage > 100 || openedPercentage <= 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({openedPercentage}%)
                            </span>
                          </Label>
                          <input
                            id="opened"
                            name="opened"
                            className="form-control"
                            type="number"
                            onChange={event => {
                              setOpened(event.target.value)
                            }}
                            value={opened}
                            disabled={delivered <= 0}
                          />
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="mb-3">
                          <Label htmlFor="unopened">
                            Unopened{" "}
                            <span
                              className={
                                unOpenedPercentage > 100 ||
                                unOpenedPercentage <= 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({unOpenedPercentage}%)
                            </span>
                          </Label>
                          <input
                            id="unopened"
                            name="unopened"
                            className="form-control"
                            type="number"
                            onChange={event => {
                              setUnOpened(event.target.value)
                            }}
                            value={unOpened}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col sm="3">
                        <div className="mb-3">
                          <Label htmlFor="subject">
                            Clicked{" "}
                            <span
                              className={
                                clickedPercentage > 100 ||
                                clickedPercentage <= 0
                                  ? "text-danger"
                                  : "text-success"
                              }
                            >
                              ({clickedPercentage}%)
                            </span>
                          </Label>
                          <input
                            id="clicked"
                            name="clicked"
                            className="form-control"
                            type="number"
                            onChange={event => {
                              setClicked(event.target.value)
                            }}
                            value={clicked}
                            disabled={opened <= 0}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="dateTime">Date and time</Label>
                          <input
                            className="form-control"
                            type="datetime-local"
                            id="dateTime"
                            onChange={event => {
                              setDateTime(event.target.value)
                            }}
                            value={dateTime}
                          />
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="campaignOrder">Campaign Order</Label>
                          <input
                            id="campaignOrder"
                            name="campaignOrder"
                            className="form-control"
                            type="number"
                            onChange={event => {
                              setCampaignOrder(event.target.value)
                            }}
                            value={campaignOrder}
                          />
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <label
                            htmlFor="example-datetime-local-input"
                            className="col-md-2 col-form-label"
                          >
                            Active
                          </label>
                          <div className="col-md-3">
                            <div className="form-check form-checkbox-outline form-check-primary">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customCheck-outlinecolor1"
                                onChange={() => {
                                  setActive(!active)
                                }}
                                checked={active}
                              />
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6">
                        <div className="mb-3 mt-3 fw-bold">
                          <Label
                            htmlFor="delivered"
                            className="fw-bold text-primary"
                          >
                            Countries List
                          </Label>
                        </div>
                      </Col>
                      <Col sm="6">
                        <div className="text-sm-end">
                          <Button
                            type="button"
                            color="success"
                            className="btn-rounded  mb-2 me-2"
                            onClick={() =>
                              setCountriesList([
                                ...countriesList,
                                {
                                  id: nanoid(),
                                  opened: 0,
                                  clicked: 0,
                                  openedPercentage: 0,
                                  clickedPercentage: 0,
                                  code: "",
                                  name: "",
                                },
                              ])
                            }
                          >
                            <i className="mdi mdi-plus me-1" />
                            Add New Country
                          </Button>
                        </div>
                      </Col>
                      <hr className="text-danger" />
                    </Row>
                    {countriesList && countriesList.length ? (
                      <>
                        <Row>
                          <Col sm="4">
                            <div className="mb-3">
                              <Label
                                htmlFor="delivered"
                                className="fw-bold text-dark"
                              >
                                Countries
                              </Label>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label
                                htmlFor="delivered"
                                className="fw-bold text-dark"
                              >
                                Opened
                              </Label>{" "}
                              <span
                                className={
                                  openedPercentageCountry != 100
                                    ? "col-md-12 col-form-label text-danger"
                                    : "col-md-12 col-form-label text-success"
                                }
                              >
                                ({openedPercentageCountry}%)
                              </span>{" "}
                              <span className="col-md-12 col-form-label text-warning">
                                (Balance: {openedBalanceCountry})
                              </span>
                            </div>
                          </Col>
                          <Col sm="3">
                            <div className="mb-3">
                              <Label
                                htmlFor="delivered"
                                className="fw-bold text-dark"
                              >
                                Clicked
                              </Label>{" "}
                              <span
                                className={
                                  clickedPercentageCountry != 100
                                    ? "col-md-12 col-form-label text-danger"
                                    : "col-md-12 col-form-label text-success"
                                }
                              >
                                ({clickedPercentageCountry}%)
                              </span>{" "}
                              <span className="col-md-12 col-form-label text-warning">
                                (Balance: {clickedBalanceCountry})
                              </span>
                            </div>
                          </Col>
                        </Row>
                        {countriesList.map(country => {
                          return (
                            <Row key={country.id}>
                              <Col sm="4">
                                <div className="mb-3">
                                  <select
                                    className="form-control"
                                    onChange={event => {
                                      handleRowChange(
                                        country.id,
                                        "country",
                                        event
                                      )
                                    }}
                                    value={`${country.code}|${country.name}`}
                                  >
                                    <option value="">Select Country</option>
                                    {countries.map(country => (
                                      <option
                                        value={`${country.code}|${country.name}`}
                                        key={country.code}
                                      >
                                        {country.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </Col>
                              <Col sm="2">
                                <div className="mb-3">
                                  <input
                                    id="opened"
                                    name="opened"
                                    className="form-control"
                                    type="number"
                                    disabled={opened <= 0 || !country.code}
                                    onChange={event => {
                                      handleRowChange(
                                        country.id,
                                        "opened",
                                        event
                                      )
                                    }}
                                    value={country.opened}
                                  />
                                </div>
                              </Col>
                              <Col sm="1">
                                <div className="mb-3">
                                  <label
                                    className={
                                      country.openedPercentage > 100 ||
                                      country.openedPercentage <= 0
                                        ? "col-md-12 col-form-label text-danger"
                                        : "col-md-12 col-form-label text-success"
                                    }
                                  >
                                    {country.openedPercentage}%
                                  </label>
                                </div>
                              </Col>
                              <Col sm="2">
                                <div className="mb-3">
                                  <input
                                    id="clicked"
                                    name="clicked"
                                    className="form-control"
                                    type="number"
                                    disabled={clicked <= 0 || !country.code}
                                    onChange={event => {
                                      handleRowChange(
                                        country.id,
                                        "clicked",
                                        event
                                      )
                                    }}
                                    value={country.clicked}
                                  />
                                </div>
                              </Col>
                              <Col sm="1">
                                <div className="mb-3">
                                  <label
                                    className={
                                      country.clickedPercentage > 100 ||
                                      country.clickedPercentage <= 0
                                        ? "col-md-12 col-form-label text-danger"
                                        : "col-md-12 col-form-label text-success"
                                    }
                                  >
                                    {country.clickedPercentage}%
                                  </label>
                                </div>
                              </Col>
                              <Col sm="2">
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm"
                                    onClick={() => removeCountry(country.id)}
                                  >
                                    <i className="bx bx-block font-size-16 align-middle me-2"></i>{" "}
                                    Delete
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          )
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        type="button"
                        color="primary"
                        className="btn "
                        disabled={!subject}
                        onClick={save}
                      >
                        Save Changes
                      </Button>
                      <Button
                        type="button"
                        color="secondary"
                        className=" "
                        onClick={() => history.push(`/xuawv2hyin`)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NewCampaign

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import MetaTags from "react-meta-tags"
//redux
import { useDispatch, useSelector } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import {
  deleteCampaign as onDeleteCampaign,
  getCampaigns as onGetCampaigns,
} from "store/actions"

const Campaigns = props => {
  const dispatch = useDispatch()
  const { history } = props

  const { campaigns } = useSelector(state => ({
    campaigns: state.campaigns.campaigns,
  }))

  const { SearchBar } = Search

  useEffect(() => {
    if (campaigns && (!campaigns.campaigns || !campaigns.campaigns.length)) {
      dispatch(onGetCampaigns())
    }
  }, [dispatch, campaigns.campaigns])

  const pageOptions = {
    sizePerPage: 10,
    totalSize: campaigns?.campaigns?.length,
    custom: true,
  }

  const CampaignColumns = () => [
    {
      dataField: "campaignOrder",
      text: "Order",
      sort: true,
    },
    {
      dataField: "campaignSubject",
      text: "Subject",
      sort: true,
      formatter: (col, row) => {
        return (
          <span
            style={{
              display: "block",
              width: 350,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {row.campaignSubject}
          </span>
        )
      },
    },
    {
      dataField: "delivered",
      text: "Delivered",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <span style={{ color: "#34c38f" }}>{row.delivered}</span>
          <span style={{ color: "#f46a6a" }}> | </span>
          <span style={{ color: "#f1b44c" }}>{row.deliveredPercentage}%</span>
        </>
      ),
    },
    {
      dataField: "opened",
      text: "Opened",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <span style={{ color: "#34c38f" }}>{row.opened}</span>
          <span style={{ color: "#f46a6a" }}> | </span>
          <span style={{ color: "#f1b44c" }}>{row.openedPercentage}%</span>
        </>
      ),
    },
    {
      dataField: "clicked",
      text: "Clicked",
      sort: true,
      formatter: (cellContent, row) => (
        <>
          <span style={{ color: "#34c38f" }}>{row.clicked}</span>
          <span style={{ color: "#f46a6a" }}> | </span>
          <span style={{ color: "#f1b44c" }}>{row.clickedPercentage}%</span>
        </>
      ),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cellContent, row) =>
        row.active ? (
          <Badge
            className={"font-size-12 badge-soft-success"}
            color="success"
            pill
          >
            Active
          </Badge>
        ) : (
          <Badge
            className={"font-size-12 badge-soft-danger"}
            color="danger"
            pill
          >
            Inactive
          </Badge>
        ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => history.push(`/xuawv2hyin/${row.campaignId}`)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => handleDeleteCampaign(row.campaignId)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ]

  const handleDeleteCampaign = campaignId => {
    console.info("handleDeleteCampaign ", campaignId)
    dispatch(onDeleteCampaign(campaignId))
    dispatch(onGetCampaigns())
  }

  const data = (campaigns?.campaigns || [])
    .sort(function (a, b) {
      return b.campaignOrder - a.campaignOrder
    })
    .map((campaign, index) => {
      return {
        campaignId: campaign.id,
        campaignOrder: campaign.campaignOrder,
        senderName: "Flydubai",
        campaignSubject: campaign.subject,
        date: campaign.sendTimeStart,
        badgeclass: campaign.status === "Sent" ? "success" : "",
        status: campaign.status === "Sent" ? "Completed" : "",
        delivered: campaign.deliveredCount,
        deliveredPercentage: "100",
        opened: campaign.openedCount,
        openedPercentage: (
          (campaign.openedCount / campaign.deliveredCount) *
          100
        ).toFixed(2),
        clicked: campaign.clickedCount,
        clickedPercentage: (
          (campaign.clickedCount / campaign.openedCount) *
          100
        ).toFixed(2),
        active: campaign.active === false ? false : true,
      }
    })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Campaign Detail | Incrementl - Mail Stats</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Campaign" breadcrumbItem="Campaigns List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="campaignId"
                    columns={CampaignColumns()}
                    data={data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="campaignId"
                        data={data}
                        columns={CampaignColumns()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={() =>
                                      history.push(`/xuawv2hyin/new`)
                                    }
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    Add New Campaign
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="campaignId"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Campaigns.propTypes = {
  campaigns: PropTypes.object,
  onGetCampaigns: PropTypes.func,
  onDeleteCampaign: PropTypes.func,
}

export default withRouter(Campaigns)
